import Image1 from './../assets/images/image1.jpg'
import Image2 from './../assets/images/image2.jpg'
import Image3 from './../assets/images/image3.jpg'

const data = [
  {
    title: 'Project 1',
    website: 'https://example.com',
    description: ` Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
    eiusmod tempor incididunt ut labore et dolore magna aliqua. At augue
    eget arcu dictum varius duis.`,
    image: Image1,
  },
  {
    title: 'Project 2',
    website: 'https://example.com',
    description: ` Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
    eiusmod tempor incididunt ut labore et dolore magna aliqua. At augue
    eget arcu dictum varius duis.`,
    image: Image2,
  },
  {
    title: 'Project 3',
    website: 'https://example.com',
    description: ` Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
    eiusmod tempor incididunt ut labore et dolore magna aliqua. At augue
    eget arcu dictum varius duis.`,
    image: Image3,
  },
  {
    title: 'Project 4',
    website: 'https://example.com',
    description: ` Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
    eiusmod tempor incididunt ut labore et dolore magna aliqua. At augue
    eget arcu dictum varius duis.`,
    image: Image1,
  },
]

export default data
